import React from 'react';
import { useHistory } from 'react-router-dom';
import VideoPlayer from '../player';
import firebase from 'firebase';

const VideoJs = ({
  videoUrl,
  currentTrack,
  currentUser,
  userProfiles,
  setEpisodeIndex,
  setSeriesIndex,
  series,
  episodeIndex,
  seriesLength,
  seriesIndex,
  subtitle,
  id: seriesId,
  setVideoDuration,
  trailerModal,
  setTrailerEnd,
  from,
  playerInstanceReady,
  isGpSubscribed,
  posterUrl
}) => {
  const playerRef = React.useRef(null);
  const history = useHistory();
  const [url, setUrl] = React.useState(videoUrl);
  const [videoEnd, setVideoEnd] = React.useState(false);
  const [showSkipTrailerBtn, setShowSkipTrailerBtn] = React.useState(false);
  const title = window?.platform_info?.title;
  const gpUser = JSON.parse(localStorage.getItem(`gp_user_${title}`));
  const robiUser = JSON.parse(localStorage.getItem(`robi_user_${title}`));
  const [playerReady, setPlayerReady] = React.useState(false);
  const [playerFullScreen, setPlayerFullScreen] = React.useState(false);
  const isPlayingRef = React.useRef(false);

  const subTitles =
    subtitle?.length > 0 &&
    subtitle.map((sub) => {
      console.log(`https://subtitles.rockstreamer.com/${sub.path}`);
      return {
        src: `https://subtitles.rockstreamer.com/${sub.path}`,
        kind: 'subtitles',
        srclang: sub.languageCode,
        label: sub.languageCode
      };
    });
  React.useEffect(() => {
    setUrl(videoUrl);
  }, [videoUrl]);

  /* Handle beforeunload to log data if the video was playing
   *  Until closing browser tab
   */
  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isPlayingRef.current) {
        analytics.logEvent('content_tracking_play', {
          content_id: currentTrack?.id.toString(),
          platform_name: window.platform_info.api_key,
          content_duration: playerRef.current.currentTime()
        });
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  /* to log data if the video was playing
   *  Until swap minimize which triggers inactive tab
   */
  React.useEffect(() => {
    if (isPlayingRef.current) {
      document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          // tab is changed
          analytics.logEvent('content_tracking_play', {
            content_id: currentTrack?.id.toString(),
            platform_name: window.platform_info.api_key,
            content_duration: playerRef.current.currentTime()
          });
        } else {
          // tab is active
        }
      });
    }
  }, []);

  // React.useEffect(() => {
  //   let currentTime = 0;
  //   const intervalId = setInterval(() => {
  //     if (playerRef.current && currentTrack?.premium && !userProfiles?.subscribe) {
  //       currentTime = playerRef.current.currentTime();
  //     }
  //     if (currentTime > 30) {
  //       setShouldPremiumStop(true);
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  React.useEffect(() => {
    if (videoEnd === true) {
      if (currentTrack?.type === 'catchupTV' || currentTrack?.type === 'catchupTVEpisode') {
        setEpisodeIndex((prev) => prev + 1);
        history.push({
          pathname: `/catchupTV/${seriesId}`,
          search: '?' + new URLSearchParams({ episode_index: episodeIndex + 2 }).toString()
        });
      } else {
        setEpisodeIndex((prev) => prev + 1);
        // to make backward compatible with old query param based series player
        if (!from) {
          history.push({
            pathname: `/series/${seriesId}`,
            search:
              '?' +
              new URLSearchParams({ season_index: 1 }).toString() +
              '&' +
              new URLSearchParams({ episode_index: episodeIndex + 2 }).toString()
          });
        }
        if (episodeIndex == series.contents.length - 1) {
          setSeriesIndex((prev) => prev + 1);
          setEpisodeIndex(0);
          // to make backward compatible with old query param based series player
          if (!from) {
            history.push({
              pathname: `/series/${seriesId}`,
              search:
                '?' +
                new URLSearchParams({ season_index: seriesIndex + 2 }).toString() +
                '&' +
                new URLSearchParams({ episode_index: 1 }).toString()
            });
          }

          if (seriesIndex == seriesLength - 1) {
            // to make backward compatible with old query param based series player
            if (!from) {
              history.push({
                pathname: `/series/${seriesId}`,
                search:
                  '?' +
                  new URLSearchParams({ season_index: 1 }).toString() +
                  '&' +
                  new URLSearchParams({ episode_index: 1 }).toString()
              });
            }
            setSeriesIndex(0);
            setEpisodeIndex(0);
          }
        }
      }
      setVideoEnd(false);
    }
  }, [videoEnd]);

  function onSkipEpisode() {
    setEpisodeIndex((prev) => prev + 1);
    if (!from) {
      history.push({
        pathname: `/series/${seriesId}`,
        search:
          '?' +
          new URLSearchParams({ season_index: 1 }).toString() +
          '&' +
          new URLSearchParams({ episode_index: episodeIndex + 2 }).toString()
      });
    }
    if (episodeIndex == series.contents.length - 1) {
      setSeriesIndex((prev) => prev + 1);
      setEpisodeIndex(0);
      if (!from) {
        history.push({
          pathname: `/series/${seriesId}`,
          search:
            '?' +
            new URLSearchParams({ season_index: seriesIndex + 2 }).toString() +
            '&' +
            new URLSearchParams({ episode_index: 1 }).toString()
        });
      }

      if (seriesIndex == seriesLength - 1) {
        if (!from) {
          history.push({
            pathname: `/series/${seriesId}`,
            search:
              '?' +
              new URLSearchParams({ season_index: 1 }).toString() +
              '&' +
              new URLSearchParams({ episode_index: 1 }).toString()
          });
        }
        setSeriesIndex(0);
        setEpisodeIndex(0);
      }
    }
  }

  React.useEffect(() => {
    if (gpUser && isGpSubscribed === 'OK') {
      setShowSkipTrailerBtn(false);
    } else if (
      currentTrack?.premium &&
      !userProfiles?.subscribe &&
      currentTrack?.trailer &&
      !currentTrack?.tvod
    ) {
      setShowSkipTrailerBtn(true);
    }
  }, [isGpSubscribed]);

  React.useEffect(() => {
    return () => {
      if (showSkipTrailerBtn == true) {
        setShowSkipTrailerBtn(false);
      }
    };
  }, []);

  const onHideSkipTrailer = () => {
    setTrailerEnd(true);
    setShowSkipTrailerBtn(false);
  };

  // myGp exit fullscreen on back button press
  // React.useEffect(() => {
  //   if(playerReady && gpUser?.partner == "GP_MYGP") {
  //     console.log("Checking fullscreen", playerRef.current.isFullscreen());
  //     // history.push({ page: 1 }, "title 1");
  //     const isFullscreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
  //     console.log('Is fullscreen?', isFullscreen);
  //   }
  //   console.log(history.action);
  //   return () => {
  //     // if player ready and gpuser and back button pressed and player in fullscreen
  //     console.log(history.action);
  //       if(playerReady && gpUser?.partner == "GP_MYGP" && history.action === "POP" && playerRef.current.isFullscreen()) {
  //         console.log("Unmount: User clicked back button");
  //         console.log("before exiting full screen call");
  //         playerRef.current.exitFullscreen();
  //         console.log("After exiting full screen call");
  //       }
  //       if(history.action === "POP") {
  //         debugger;
  //       }
  //       if(history.length == 2) {
  //         debugger;
  //       }
  //       if(playerRef.current.isFullscreen()) {
  //         console.log("Player is in full screen mode");
  //       }
  //       // if(history.action === "PUSH" && isFullscreen && playerRef.current.isFullscreen()) {
  //       //   console.log("Action push and Checking fullscreen");
  //       // }
  //       // if(playerReady) {
  //       //   playerRef.current.on('fullscreenchange', () => {
  //       //     if(playerRef.current.isFullscreen() && history.action === "POP") {
  //       //       playerRef.current.exitFullscreen();
  //       //       console.log("Full screen minimized");
  //       //     }
  //       //   })
  //       // }
  //   }
  // }, [playerReady, playerFullScreen])

  React.useEffect(() => {
    const handlePopState = () => {
      // window.location.replace(
      //   `https://youtube.com`
      // );
      if (
        playerReady &&
        (gpUser?.partner == 'GP_MYGP' || robiUser?.partner == 'ROBI_MYROBI') &&
        playerRef.current.isFullscreen()
      ) {
        playerRef.current.exitFullscreen();
      }
      console.log('Player ready', playerReady);
      console.log('Player currently fullscreen via videojs', playerRef.current.isFullscreen());
      console.log('Player currently fullscreen via state', playerFullScreen);
      // return;
    };
    if (
      (gpUser?.partner == 'GP_MYGP' || robiUser?.partner == 'ROBI_MYROBI') &&
      window.history.length == 2
    ) {
      window.history.pushState(null, null, document.URL);
    }
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [playerReady]);

  // React.useEffect(() => {
  //   let initialStateIndex = window.history.state ? window.history.state.index : window.history.length - 1;
  //   console.log("Initial State index", initialStateIndex);

  //   const handlePopstate = (event) => {
  //     const currentStateIndex = window.history.state ? window.history.state.index : window.history.length - 1;
  //     console.log("Current State Index", currentStateIndex);

  //     if(currentStateIndex == 1) {
  //       window.history.pushState
  //     }

  //     // Compare the current state index with the state index before the event
  //     if (currentStateIndex < initialStateIndex) {
  //       // Back button pressed
  //       console.log('back button pressed');
  //       debugger;
  //       // Add your logic here
  //     }

  //     // Update the initial state index for the next comparison
  //     initialStateIndex = currentStateIndex;
  //   };

  //   // Add event listener for the popstate event
  //   window.addEventListener('popstate', handlePopstate);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('popstate', handlePopstate);
  //   };
  // }, [playerReady]); // Empty dependency array ensures the effect runs only once when the component mounts

  // React.useEffect(() => {
  //   window.addEventListener('popstate', function(event) {
  //     // Check if the video is in fullscreen
  //     console.log("Before Full screen", playerRef.current.isFullscreen());
  //     if (playerRef.current.isFullscreen()) {
  //       // Exit fullscreen and handle any additional logic
  //       console.log("Fired here");
  //       playerRef.current.exitFullscreen();
  //       // Add your custom logic here after exiting fullscreen

  //       // Navigate back in history manually
  //       history.back();
  //     }
  //   });

  //   return () => {
  //     window.removeEventListener('popstate', () => {})
  //   }
  // }, [playerReady])

  const autoplayStatus =
    currentTrack?.live || currentTrack?.type == 'tv'
      ? true
      : gpUser?.partner == 'GP_MYGP' ||
        robiUser?.partner == 'ROBI_MYROBI' ||
        !userProfiles?.subscribe
      ? false
      : !trailerModal;

  // const overrideNative = /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream;
  const overrideNative = false;

  const videoJsOptions = {
    // lookup the options in the docs for more options
    nativeVideoTracks: !overrideNative,
    nativeAudioTracks: !overrideNative,
    nativeTextTracks: !overrideNative,
    autoplay: autoplayStatus,
    // autoplay: false,
    playsinline: true,
    controls: true,
    responsive: true,
    poster: `${process.env.REACT_APP_IMAGE_URL}400xauto/${posterUrl}`,
    vhs: { overrideNative: true },
    hls: { overrideNative: true },
    sources: [
      {
        src: url,
        type: 'application/x-mpegURL',
        label: 'SD'
      },
      {
        src: url,
        type: 'application/x-mpegURL',
        label: 'HD'
      }
    ],
    userActions: {
      hotkeys: {}
    },
    tracks: subTitles,
    preload: 'auto',
    liveui: true,
    html5: {
      hls: {
        enableLowLatency: true, // If applicable
        maxBufferLength: 60,
        maxRetry: 3, // Retry on playlist failure
        startLevel: -1 // Automatically choose the best available quality
      }
    },
    plugins: {
      mux: {
        debug: false,
        data: {
          env_key: 'iljf25jpf0nellio2h5hfo9lt', // required
          // Site Metadata
          video_id: currentTrack?.id,
          video_title: currentTrack?.title
        }
      }
    }

    // tracks: [
    //   {
    //     src: 'https://kot-politiken.s3-eu-west-1.amazonaws.com/2019/114_en.vtt.txt',
    //     kind: 'subtitles',
    //     srclang: 'en',
    //     label: 'English'
    //   },
    //   { src: 'https://domain/mysub.vtt', kind: 'subtitles', srclang: 'ban', label: 'Bangla' }
    // ]
  };

  const analytics = firebase.analytics();
  const handlePlayerReady = (player) => {
    playerRef.current = player;
    playerInstanceReady(player);
    console.log('Analytics logging event');
    analytics.logEvent('content_tracking', {
      content_id: currentTrack?.id.toString(),
      platform_name: window.platform_info.api_key,
      content_name: currentTrack?.title,
      type: currentTrack?.type,
      cast: currentTrack?.casts?.map((cast) => cast.name).join(','),
      createdAt: currentTrack?.createdAt,
      user_id: currentUser?.id
    });

    player.on('dispose', () => {
      analytics.logEvent('content_tracking_play', {
        content_id: currentTrack?.id.toString(),
        platform_name: window.platform_info.api_key,
        content_duration: playerRef.current.currentTime()
      });
    });

    player.on('ready', () => {
      console.log('Player is ready');
      setPlayerReady(true);
    });

    player.on('play', () => {
      const gpUser = JSON.parse(localStorage.getItem(`gp_user_${title}`));
      const robiUser = JSON.parse(localStorage.getItem(`robi_user_${title}`));
      const userAgent = navigator.userAgent;
      if (gpUser?.partner == 'GP_MYGP' || robiUser?.partner == 'ROBI_MYROBI') {
        console.log('Content Playing event');
        player.requestFullscreen();
        player.isFullscreen(true);
      }
      isPlayingRef.current = true;
      console.log('IsplayingRef in play', isPlayingRef);
    });

    player.on('pause', () => {
      isPlayingRef.current = false;
      console.log('IsplayingRef in pause', isPlayingRef);
    });

    player.on('fullscreenchange', () => {
      console.log('Checking full screen event');
    });

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('adend', function () {
      console.log('Content resumed');
      player.play();
      player.muted(false);
      // player.volume(100);
    });
  };

  const onVideoEnd = () => {
    if (currentTrack?.premium && !userProfiles?.subscribe) {
      setTrailerEnd(true);
    }
    analytics.logEvent('content_tracking_play', {
      content_id: currentTrack?.id.toString(),
      platform_name: window.platform_info.api_key,
      content_duration: playerRef.current.duration()
    });
    setEpisodeIndex && setVideoEnd(true);
  };

  return (
    <VideoPlayer
      options={videoJsOptions}
      onEnd={onVideoEnd}
      currentTrack={currentTrack}
      userProfiles={userProfiles}
      onReady={handlePlayerReady}
      setVideoDuration={setVideoDuration}
      onSkipEpisode={onSkipEpisode}
      seriesId={seriesId}
      showSkipTrailerBtn={showSkipTrailerBtn}
      onHideSkipTrailer={onHideSkipTrailer}
      seriesIndex={seriesIndex}
      episodeIndex={episodeIndex}
      from={from}
      setEpisodeIndex={setEpisodeIndex}
      setSeriesIndex={setSeriesIndex}
    />
  );
};

export default VideoJs;
